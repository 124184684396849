<template>
  <!-- 城市管理 -->
  <div id="citySafeguard" ref="citySafeguard">
    <div class="header">
      <div class="left">
        <div class="city">
          <h4>城市</h4>
          <a-input
            v-model:value="searchCity"
            placeholder="请输入城市名称"
            style="width: 200px"
          />
        </div>
        <div class="area">
          <h4>是否有辖区</h4>
          <a-select
            v-model:value="isHasArea"
            :options="isHasAreaList"
            placeholder="请选择"
            style="width: 200px"
          ></a-select>
        </div>
        <a-button type="primary" @click="GetData">查询</a-button>
      </div>
      <div class="right">
        <a-button type="primary" @click="OpenModal('新增辖区')">新增</a-button>
      </div>
    </div>
    <div class="main">
      <a-table
        :loading="isloading"
        :columns="columns"
        :data-source="CityTableData"
        :pagination="CitySizePagination"
      >
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'operation'">
            <a-button type="primary" @click="Administration(record)"
              >管理辖区</a-button
            >
          </template>
        </template>
      </a-table>
    </div>
    <a-modal
      :getContainer="$refs.citySafeguard"
      v-model:visible="visible"
      :title="AdministrationTitle + '-管理辖区'"
      :maskClosable="false"
      width="80%"
      style="top: 30px"
      :footer="false"
    >
      <div class="header">
        <div class="left">
          <div class="city">
            <a-input
              v-model:value="searchArea"
              @pressEnter="Administration('')"
              placeholder="请输入辖区名称"
              style="width: 200px"
            />
          </div>
          <div class="area">
            <a-select
              v-model:value="isValid"
              :options="isValidList"
              placeholder="请选择"
              style="width: 200px"
            ></a-select>
          </div>
          <a-button type="primary" @click="Administration('')">查询</a-button>
        </div>
        <div class="right">
          <a-button type="primary" @click="OpenModal('城市新增辖区')"
            >新增</a-button
          >
        </div>
      </div>
      <div class="main">
        <a-table
          size="small"
          :loading="isloading"
          :columns="AreaTableHead"
          :data-source="AreaTableData"
          :pagination="RegionSizePagination"
        >
          <!-- <template #bodyCell="{ column, text }">
      <template v-if="column.dataIndex === 'name'">
        <a>{{ text }}</a>
      </template>
    </template> -->
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'status'">
              <a-switch
                v-model:checked="record.status"
                @change="changeStatus(record)"
              />
            </template>
            <template v-if="column.key === 'operation'">
              <a-button type="primary" @click="OpenModal('编辑辖区', record)"
                >编辑</a-button
              >
            </template>
          </template>
        </a-table>
      </div>
      <a-modal
        :getContainer="$refs.citySafeguard"
        v-model:visible="addEditModal"
        :title="modalTitle"
        :maskClosable="false"
        @cancel="closeModal"
        width="40%"
        :footer="false"
      >
        <a-form
          ref="modalRef"
          :model="formState"
          name="basic"
          :label-col="{ span: 7 }"
          :wrapper-col="{ span: 10 }"
          autocomplete="off"
          :rules="rules"
          @finish="submit"
        >
          <a-form-item label="城市" name="city">
            <a-select
              show-search
              :filter-option="cityFilter"
              v-model:value="formState.city"
              :options="CitySelectList"
              placeholder="请选择"
              style="width: 200px"
            ></a-select>
          </a-form-item>

          <a-form-item label="辖区名称" name="region">
            <a-input style="width: 200px" v-model:value="formState.region" />
          </a-form-item>
          <a-form-item label="状态" name="status">
            <a-switch
              checked-children="有效"
              un-checked-children="无效"
              v-model:checked="formState.status"
            />
          </a-form-item>

          <a-form-item :wrapper-col="{ offset: 10, span: 14 }">
            <a-button type="primary" htmlType="submit">提交</a-button>
          </a-form-item>
        </a-form>
      </a-modal>
    </a-modal>

    <a-modal
      :getContainer="$refs.citySafeguard"
      v-model:visible="CityAddModal"
      :title="modalTitle"
      :maskClosable="false"
      @cancel="closeModal"
      width="40%"
      :footer="false"
    >
      <a-form
        ref="modalRef"
        :model="formState"
        name="basic"
        :label-col="{ span: 7 }"
        :wrapper-col="{ span: 10 }"
        autocomplete="off"
        :rules="rules"
        @finish="submit"
      >
        <a-form-item label="城市" name="city">
          <a-select
            show-search
            v-model:value="formState.city"
            :filter-option="cityFilter"
            :options="CitySelectList"
            placeholder="请选择"
            style="width: 200px"
          ></a-select>
        </a-form-item>

        <a-form-item label="辖区名称" name="region">
          <a-input style="width: 200px" v-model:value="formState.region" />
        </a-form-item>
        <a-form-item label="状态" name="status">
          <a-switch
            checked-children="有效"
            un-checked-children="无效"
            v-model:checked="formState.status"
          />
        </a-form-item>

        <a-form-item :wrapper-col="{ offset: 10, span: 14 }">
          <a-button type="primary" htmlType="submit">提交</a-button>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
// import axios from "@/http/request.js";

import { onMounted, reactive, toRefs, ref } from "vue";
import {
  GetRegionTotals,
  InsertRegion,
  GetRegionItems,
  EditRegion,
  GetCityData,
} from "@/api/socialSecurityManagement";
import { Modal } from "ant-design-vue";
export default {
  name: "Order",
  setup() {
    const modalRef = ref();
    const state = reactive({
      rules: {
        city: [
          {
            required: true,
            message: "请选择城市",
            trigger: "change",
          },
        ],
        region: [
          {
            required: true,
            message: "请填写辖区名称",
            trigger: "change",
          },
        ],
      }, //表单校验规则
      isloading: false, // 是否加载中
      visible: false, //是否打开管理辖区弹出框
      addEditModal: false, //是否打开新增或者编辑的弹出框
      CityAddModal: false, //城市页面新增辖区弹框
      isHasAreaList: [
        {
          value: "-1",
          label: "全部",
        },
        {
          value: "0",
          label: "否",
        },
        {
          value: "1",
          label: "是",
        },
      ], //是否有辖区下拉框

      isValidList: [
        {
          value: "-1",
          label: "全部",
        },
        {
          value: "0",
          label: "无效",
        },
        {
          value: "1",
          label: "有效",
        },
      ], //是否有效下拉框

      // 辖区的分页数据
      RegionSizePagination: {
        pageNo: 1,
        pageSize: 10, // 默认每页显示数量
        showSizeChanger: true, // 显示可改变每页数量
        pageSizeOptions: ["10", "20", "50", "100"], // 每页数量选项
        showTotal: (total) => `共 ${total} 条`, // 显示总数
        onShowSizeChange: (current, pageSize) =>
          onRegionPageChange(current, pageSize), // 改变每页数量时更新显示
        onChange: (page, pageSize) => onRegionPageChange(page, pageSize), //点击页码事件
        total: 0, //总条数
      }, // 辖区分页数据

      // 城市的分页数据
      CitySizePagination: {
        pageNo: 1,
        pageSize: 10, // 默认每页显示数量
        showSizeChanger: true, // 显示可改变每页数量
        pageSizeOptions: ["10", "20", "50", "100"], // 每页数量选项
        showTotal: (total) => `共 ${total} 条`, // 显示总数
        onShowSizeChange: (current, pageSize) =>
          onCityPageChange(current, pageSize), // 改变每页数量时更新显示
        onChange: (page, pageSize) => onCityPageChange(page, pageSize), //点击页码事件
        total: 0, //总条数
      }, // 城市分页数据
      searchCity: "", //搜索城市框数据
      searchArea: "", //搜索辖区框数据
      isValid: "-1", //是否生效
      isHasArea: "-1", //是否有辖区
      AdministrationTitle: "", // 管理辖区标题
      modalTitle: "新增辖区", //新增/编辑弹出框标题
      modalType: "addCity",
      columns: [
        {
          title: "序号",
          dataIndex: "no",
          key: "no",
          align: "center",
        },
        {
          title: "城市名",
          dataIndex: "city",
          key: "city",
          align: "center",
        },
        {
          title: "辖区数",
          dataIndex: "regionTotal",
          key: "regionTotal",
          align: "center",
        },
        {
          title: "操作",
          dataIndex: "operation",
          key: "operation",
          align: "center",
        },
      ],
      CityTableData: [],
      AreaTableHead: [
        {
          title: "序号",
          dataIndex: "no",
          key: "no",
          align: "center",
        },
        {
          title: "辖区名",
          dataIndex: "region",
          key: "region",
          align: "center",
        },
        {
          title: "状态",
          dataIndex: "status",
          key: "status",
          align: "center",
        },
        {
          title: "操作",
          dataIndex: "operation",
          key: "operation",
          align: "center",
        },
      ],
      AreaTableData: [],
      CitySelectList: [], //弹出框城市下拉数据
      formState: {
        city: "",
        region: "",
        status: true,
        pkId: "",
      },
      isEdit: false, //是否点的编辑
    });

    onMounted(() => {
      getCityData();
      GetData("first");
    });

    // 筛选城市
    const cityFilter = (inputVal, option) => {
      return option.value.indexOf(inputVal) >= 0;
    };

    const GetData = (type) => {
      state.isloading = true;
      if (type == "first") {
        // 首次进页面
        GetRegionTotals({
          city: "",
          isExistData: -1,
        })
          .then((res) => {
            state.CityTableData = res.data.items;
            // state.CityTableData.map((item) => {
            //   state.CitySelectList.push(item.city);
            // });
            state.CitySizePagination.total = res.data.total;
            state.isloading = false;
          })
          .catch((error) => {
            console.log(error);
            Modal.error({
              content: error.request.response,
            });
            state.isloading = false;
          });
      } else {
        // 查询
        GetRegionTotals({
          city: state.searchCity,
          isExistData: state.isHasArea,
        })
          .then((res) => {
            state.CityTableData = res.data.items;
            state.isloading = false;
          })
          .catch((error) => {
            Modal.error({
              content: error.request.response,
            });
            state.isloading = false;
          });
      }
    };

    //城市更换页码事件
    const onCityPageChange = async (page, pageSize) => {
      state.CitySizePagination.pageNo = page;
      state.CitySizePagination.pageSize = pageSize;
      let params = {
        city: "",
        region: state.searchArea,
        isEnabled: state.isValid,
        page: page,
        rows: pageSize,
      };
      await GetRegionTotals(params)
        .then((res) => {
          state.AreaTableData = res.data.items;
          state.CitySizePagination.total = res.data.total;
          state.isloading = false;
        })
        .catch((error) => {
          Modal.error({
            content: error.request.response,
          });
          state.isloading = false;
        });
    };

    // 辖区更换页码事件
    const onRegionPageChange = async (page, pageSize) => {
      state.RegionSizePagination.pageNo = page;
      state.RegionSizePagination.pageSize = pageSize;
      let params = {
        city: state.AdministrationTitle,
        region: state.searchArea,
        isEnabled: state.isValid,
        page: page,
        rows: pageSize,
      };
      await GetRegionItems(params)
        .then((res) => {
          state.AreaTableData = res.data.items;
          state.RegionSizePagination.total = res.data.total;
          state.isloading = false;
        })
        .catch((error) => {
          Modal.error({
            content: error.request.response,
          });
          state.isloading = false;
        });
    };

    // 点击管理辖区
    const Administration = async (val) => {
      // 如果val为空，则是点击查询
      // 如果val有值，则是点击管理辖区
      if (val) {
        state.searchArea = "";
        state.AdministrationTitle = val.city;
      }
      let params = {
        city: val ? val.city : state.AdministrationTitle,
        region: state.searchArea,
        isEnabled: state.isValid,
        page: state.RegionSizePagination.pageNo,
        row: state.RegionSizePagination.pageSize,
      };
      state.isloading = true;
      await GetRegionItems(params)
        .then((res) => {
          state.AreaTableData = res.data.items;
          state.RegionSizePagination.total = res.data.total;
          state.isloading = false;
        })
        .catch((error) => {
          Modal.error({
            content: error.request.response,
          });
          state.isloading = false;
        });

      state.visible = true;
    };

    //修改管理辖区的状态
    const changeStatus = (data) => {
      let params = {
        pkId: data.pkId,
        region: data.region,
        isEnabled: data.status,
      };
      EditRegion(params)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          Modal.error({
            content: err.request.response,
          });
        });
    };

    // 点击新增/编辑打开弹出框
    const OpenModal = (type, data) => {
      if (type == "编辑辖区") {
        state.isEdit = true;
        state.formState = {
          city: state.AdministrationTitle,
          region: data.region,
          status: data.status,
          pkId: data.pkId,
        };
        state.addEditModal = true;
      } else if (type == "城市新增辖区") {
        state.isEdit = true;
        state.formState = {
          city: state.AdministrationTitle,
          region: "",
          status: false,
        };
        state.addEditModal = true;
      } else {
        // 城市新增弹出框
        state.isEdit = false;
        state.formState = {
          city: "",
          region: "",
          status: false,
        };
        state.CityAddModal = true;
      }
      state.modalTitle = type;
    };

    // 新增弹出框提交
    const submit = () => {
      let params = {
        city: state.formState.city,
        region: state.formState.region,
        isEnabled: state.formState.status,
        pkId: state.formState.pkId,
      };
      if (params.pkId) {
        // pkId有值则表示是编辑提交
        EditRegion(params)
          .then((res) => {
            if (res.data == "编辑成功") {
              Modal.success({
                content: res.data,
                onOk() {
                  state.addEditModal = false;
                  Administration("");
                },
              });
              state.isloading = false;
            }
          })
          .catch((err) => {
            Modal.error({
              content: err.request.response,
            });
            state.isloading = false;
          });
      } else {
        // 新增
        state.isloading = true;
        InsertRegion(params)
          .then((res) => {
            if (res.data == "新增成功") {
              Modal.success({
                content: res.data,
                async onOk() {
                  state.isloading = true;
                  await GetRegionItems({
                    city: state.formState.city,
                  })
                    .then((res) => {
                      state.AdministrationTitle = state.formState.city;
                      state.AreaTableData = res.data.items;
                      state.addEditModal = false;
                      state.visible = true;
                    })
                    .catch((err) => {
                      console.log(1);
                      Modal.error({
                        content: err.request.response,
                      });
                    });
                  state.isloading = false;
                },
              });
            }
          })
          .catch((err) => {
            Modal.error({
              content: err.request.response,
            });
            state.isloading = false;
          });
      }
    };

    // 获取城市数据
    const getCityData = async () => {
      await GetCityData()
        .then((res) => {
          let l = [];
          res.data.forEach((i) => {
            let o = {
              value: i === "全部" ? "" : i,
              label: i,
            };
            l.push(o);
          });
          l.shift();
          state.CitySelectList = l;
        })
        .catch((err) => {
          Modal.error({
            content: err.response.data,
          });
        });
    };

    // 关闭弹窗重置数据
    const closeModal = () => {
      modalRef.value.resetFields();
    };
    return {
      ...toRefs(state),
      modalRef,
      OpenModal,
      Administration,
      submit,
      GetData,
      changeStatus,
      onCityPageChange,
      onRegionPageChange,
      closeModal,
      getCityData,
      cityFilter,
    };
  },
};
</script>

<style lang="less" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  .left {
    display: flex;
    align-items: flex-end;
    .city {
      margin-right: 30px;
    }
    .area {
      margin-right: 30px;
    }
  }
}
.main {
  margin-top: 30px;
}
// /deep/ .ant-modal-mask{
//   z-index: 900 !important;
// }
</style>
