import http from "@/api/axios.js";
// 获取城市区域维护总数列表
export function GetRegionTotals(data) {
  return http({
    url: `/api/Region/GetRegionTotals`,
    method: "get",
    params: data,
  });
}
// 新增区域
export function InsertRegion(data) {
  return http({
    url: `/api/Region/InsertRegion`,
    method: "post",
    data: data,
  });
}

// 获取城市区域维护列表
export function GetRegionItems(data) {
  return http({
    url: `/api/Region/GetRegionItems`,
    method: "get",
    params: data,
  });
}

// 城市区域状态修改
export function EditRegion(data) {
  return http({
    url: `/api/Region/EditRegion`,
    method: "post",
    data: data,
  });
}

// 获取城市下拉List
export function GetCityData(data) {
  return http({
    url: `/api/SocialProduct/GetProductCities`,
    method: "get",
    params: data,
  });
}
